import React, { Component } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import Footer from "../components/footer"
import "../utils/scss/all.scss"

class DefaultLayout extends Component {
  render() {
    return (
      <div className="siteWrapper">
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Barlow:400,400i,700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        {this.props.children}
        <Footer />
      </div>
    )
  }
}

DefaultLayout.propTypes = {
  children: PropTypes.object.isRequired,
}

export default DefaultLayout
