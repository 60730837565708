import React, { Component } from "react"
import { Link } from "gatsby"

import footerLogo from "../images/logo-footer-prha.svg"
import partnerAcuity from "../images/partners/acuity.png"
import partnerAAM from "../images/partners/almshouse-association-member.png"
import partnerEAC from "../images/partners/eac.png"
import partnerHQN from "../images/partners/hqn.png"
import partnerNHF from "../images/partners/national-housing-federation.png"

class Footer extends Component {
  render() {
    const date = new Date();
    const partners = [
        {
            title: "Acuity",
            img: partnerAcuity
        },
        {
            title: "National Housing Federation",
            img: partnerNHF
        },
        {
            title: "HQN",
            img: partnerHQN
        },
        {
            title: "EAC 2019",
            img: partnerEAC
        },
        {
            title: "Almshouse Association Member",
            img: partnerAAM
        },
    ]    

    return (
      <footer className="siteFooter">
          <div className="containerFluid">
            <div className="row">
                <div className="col-bp1-12 col-bp3-3">
                    <img className="siteFooter__logo" src={footerLogo} alt="PRHA" />      
                </div>
                <div className="col-bp1-12 col-bp3-9">

                    <div className="siteFooter__blocks">
                        <div className="siteFooter__block">
                            <p>&copy; {date.getFullYear()} PRHA. All Rights Reserved</p>
                            <Link to="/cookie-policy" className="siteFooter__link siteFooter__link--bordered">
                                Cookie Policy
                            </Link>
                            <Link to="/privacy-notice" className="siteFooter__link">
                                Privacy Notice
                            </Link>
                        </div>

                        <div className="siteFooter__block">
                            <p>Find PRHA</p>
                            <a href="https://www.instagram.com/portsmouth_rotary_housing/" className="siteFooter__link siteFooter__link--bordered">Instagram</a>
                            <a href="https://twitter.com/RotaryHousing" className="siteFooter__link">Twitter</a>
                        </div>

                        <div className="siteFooter__block">
                            <p>Member's Area</p>
                            <Link to="/members/login" className="siteFooter__link">
                                Login
                            </Link>
                        </div>

                        <div className="siteFooter__block">
                            <p>Site design &amp; build</p>
                            <a href="http://www.broadgatecreative.co.uk/" className="siteFooter__link">Broadgate Creative</a>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col-bp1-12">
                
                    <div className="partners">
                        {partnersLoop(partners)}
                    </div>

                </div>            
            </div>
        </div>
      </footer>
    )
  }
}

function partnersLoop(items){
    return items.map(item => {
        return <img key={item.title} className="partner" src={item.img} alt={item.title} />
    })
}

export default Footer
