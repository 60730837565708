import React, { Component } from "react"
import { Link } from "gatsby"

class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navActive: false,
      navHeight: `0px`,
    }

    this.handleNavClick = this.handleNavClick.bind(this)
  }

  componentDidMount() {
    const navHeight = window.innerWidth < 1020 ? `0px` : `auto`
    this.setState({ navHeight })
  }

  handleNavClick(event) {
    event.preventDefault()
    const siteNavH = document.querySelector(".siteNav ul")
    let navHeight
    if (this.state.navActive) {
      navHeight = window.innerWidth < 1020 ? `0px` : `auto`
    } else {
      navHeight =
        window.innerWidth < 1020 ? `${siteNavH.clientHeight}px` : `auto`
    }

    this.setState({
      navActive: !this.state.navActive,
      navHeight,
    })
  }

  render() {
    const classDropdownTrigger =
      "nav-cta" + (this.state.navActive ? " active" : "")
    const classDropdownMenu =
      "siteNav" + (this.state.navActive ? " active" : "")

    return (
      <div className="mainNavigation">
        <button
          className={classDropdownTrigger}
          onClick={this.handleNavClick}
          title="Menu Toggle"
        >
          <span className="nav-cta__text">Menu</span>
          <span className="lines-button x">
            <span className="lines"></span>
          </span>
        </button>
        <nav
          className={classDropdownMenu}
          style={{ height: this.state.navHeight }}
        >
          <ul>
            {this.props.nav.map(({ node }) =>
              node.menuItems.nodes.map(item => {
                return (
                  <li className="menuItem" key={item.path}>
                    <Link to={item.path} activeClassName="current">
                      {item.label}
                    </Link>
                  </li>
                )
              })
            )}
          </ul>
        </nav>
      </div>
    )
  }
}

export default Nav
